<template>
  <div class="card" :class="{ 'card-expand': expanded }">
    <div class="card-header">
      {{$t('sms.infos.tariffed-component.title')}}
      <ul class="actions top-right">
        <li>
          <a href="javascript:void(0)" @click="changeChart">
            <i v-if="type == 'line'" class="icon dripicons-graph-pie"></i>
            <i v-else-if="type == 'pie'" class="icon dripicons-graph-line"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="fetch">
            <i class="icon dripicons-clockwise"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="expand">
            <i v-if="expanded" class="icon dripicons-contract-2"></i>
            <i v-else class="icon dripicons-expand-2"></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="block-el">
      <template v-if="fetched">
        <div class="card-body">
          <template v-if="successMessages || errorMessages">
            <line-chart
              v-if="type === 'line'"
              :chartdata="line.chartdata"
              :options="line.options"
              :height="chartHeight"
            />
            <doughnut-chart
              v-else-if="type === 'pie'"
              :chartdata="pie.chartdata"
              :options="pie.options"
              :height="163"
            />
          </template>
          <div v-else class="text-center table-placeholder">
            <i class="far fa-chart-bar font-size-80"></i>
            <h5 class="card-title m-t-20">{{$t('sms.infos.none')}}</h5>
            <p>{{$t('sms.infos.none-msg')}}</p>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-6">
              <div class="card bg-success text-center p-3 m-b-0">
                <h5 class="text-white info-home">{{$tc('generic-str.tariffed-a', 2)}}</h5>
                <span class="text-white font-size-40 font-w-300">{{
                  successMessages
                }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="card bg-danger text-center p-3 m-b-0">
                <h5 class="text-white info-home">{{$t('generic-str.status.lbl-errors')}}</h5>
                <span class="text-white font-size-40 font-w-300">{{
                  errorMessages
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="qt-block-ui" />
    </div>
  </div>
</template>

<script>
import RCSService from '@/services/rcs.service';
import LineChart from '@/components/LineChart.vue';
import DoughnutChart from '@/components/DoughnutChart.vue';

export default {
  name: 'TariffedCard',
  components: { LineChart, DoughnutChart },
  props: ['form', 'filters'],
  data() {
    return {
      chartHeight: window.innerWidth >= 768 ? 102 : 468,
      fetched: true,
      expanded: false,
      type: 'line',
      successMessages: 0,
      errorMessages: 0,
      line: {
        chartdata: {
          labels: [],
          datasets: [
            {
              label: this.$tc('generic-str.tariffed-a', 2),
              borderColor: '#2fbfa0',
              data: [],
              fill: false,
              lineTension: 0.1,
            },
            {
              label: this.$t('generic-str.status.lbl-errors'),
              borderColor: '#ff5c75',
              data: [],
              fill: false,
              lineTension: 0.1,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0,
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    day: 'DD/MM',
                    hour: 'HH:mm:ss',
                  },
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 7,
                },
              },
            ],
          },
        },
      },
      pie: {
        chartdata: {
          labels: [this.$tc('generic-str.tariffed-a', 2), this.$t('generic-str.status.lbl-errors')],
          datasets: [
            {
              backgroundColor: ['#2fbfa0', '#ff5c75'],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
        },
      },
    };
  },
  created() {
    if (window.innerWidth >= 1920) {
      this.chartHeight = 102;
    } else if (window.innerWidth >= 1280) {
      this.chartHeight = 174;
    } else if (window.innerWidth >= 768) {
      this.chartHeight = 244;
    } else {
      this.chartHeight = 466;
    }
    // this.fetch();
  },
  methods: {
    fetch() {
      this.fetched = false;
      this.line.chartdata.datasets[0].data = [];
      this.line.chartdata.datasets[1].data = [];
      this.line.chartdata.labels = [];
      this.pie.chartdata.datasets[0].data = [];
      this.successMessages = 0;
      this.errorMessages = 0;

      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;
      console.log(this.filters);
      Promise.all([
        RCSService.getInsights({
          direction: 'outbound',
          filters: [
            {
              field: 'status',
              values: ['delivered', 'sent', 'read'],
            },
            ...this.filters,
          ],
          ...this.form,
        }),
        RCSService.getInsights({
          direction: 'outbound',
          filters: [
            {
              field: 'status',
              values: ['failed'],
            },
            ...this.filters,
          ],
          ...this.form,
        }),
      ])
        .then(
          (responses) => {
            this.fetched = true;
            responses[0].forEach((element, index) => {
              this.line.chartdata.datasets[0].data.push(element.values[0]);
              this.line.chartdata.datasets[1].data.push(
                responses[1][index].values[0],
              );
              this.line.chartdata.labels.push(element.date_time);
              this.successMessages += element.values[0];
              this.errorMessages += responses[1][index].values[0];
            });
            this.pie.chartdata.datasets[0].data.push(this.successMessages);
            this.pie.chartdata.datasets[0].data.push(this.errorMessages);
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    changeChart() {
      this.type = this.type === 'pie' ? 'line' : 'pie';
    },
    expand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 200px;
}
</style>
